import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TooltipWithoutIcon from "../TooltipMoreInfo/TooltipWithoutIcon";
import { useSelector } from "react-redux";
import { maxLength } from "../../../utils/maxLength";
import TextFieldAutocompleteArticulo from "../TextFieldAutocompleteArticulo";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
  option: {
    "&:hover": {
      backgroundColor: "#B2B2B2 !important",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    fontSize: 13,
    fontWeight: 700,
    background: theme.palette.common.black,
    color: "#fff",
  },
  labelRoot: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1em",
    },
  },
  fontSizeOptions: {
    fontSize: 18,
  },
}));

export const OptionsLabel = ({ articulo }) => {
  const classes = useStyles();

  return (
    <div>
      <TooltipWithoutIcon
        placement={"bottom"}
        body={
          <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
            {!(
              articulo.repuesto_sucursal === null ||
              articulo.repuesto_sucursal.deposito === undefined
            )
              ? `${
                  articulo.repuesto_sucursal.deposito.descripcion +
                  " - " +
                  articulo.repuesto_sucursal.zona.descripcion +
                  " - " +
                  articulo.repuesto_sucursal.estante.descripcion +
                  " - " +
                  articulo.repuesto_sucursal.fila.descripcion
                }`
              : "Sin ubicación"}
          </h6>
        }
      >
        <div>
          <span
            className={classes.fontSizeOptions}
            style={{ fontWeight: "bolder" }}
          >
            C:
          </span>
          <span className={classes.fontSizeOptions}>
            {articulo.codProveedor || "N/A"}
          </span>{" "}
          --
          <span
            className={classes.fontSizeOptions}
            style={{ fontWeight: "bolder" }}
          >
            AUX:
          </span>
          <span className={classes.fontSizeOptions}>
            {articulo.codAuxiliar || "N/A"}
          </span>{" "}
          {articulo.codigo_auxiliar_2 && (
            <>
              --
              <span
                className={classes.fontSizeOptions}
                style={{ fontWeight: "bolder" }}
              >
                AUX2:
              </span>
              <span className={classes.fontSizeOptions}>
                {articulo.codigo_auxiliar_2 || "N/A"}
              </span>{" "}
            </>
          )}
          {articulo.codigo_auxiliar_3 && (
            <>
              --
              <span
                className={classes.fontSizeOptions}
                style={{ fontWeight: "bolder" }}
              >
                AUX3:
              </span>
              <span className={classes.fontSizeOptions}>
                {articulo.codigo_auxiliar_3 || "N/A"}
              </span>{" "}
            </>
          )}
          --
          <span
            className={classes.fontSizeOptions}
            style={{ fontWeight: "bolder" }}
          >
            D:
          </span>
          <span className={classes.fontSizeOptions}>
            {articulo.descripcionProveedor}
          </span>
          --
          <span
            className={classes.fontSizeOptions}
            style={{ fontWeight: "bolder" }}
          >
            S:
          </span>
          <span className={classes.fontSizeOptions}>
            {articulo.repuesto_sucursal
              ? articulo.repuesto_sucursal.stockDisponible
              : "N/A"}
          </span>{" "}
          --
          <span
            className={classes.fontSizeOptions}
            style={{ fontWeight: "bolder" }}
          >
            P:
          </span>
          <span className={classes.fontSizeOptions}>
            {articulo.alias_proveedor || "N/A"}
          </span>
          --
          <span
            className={classes.fontSizeOptions}
            style={{ fontWeight: "bolder" }}
          >
            $
          </span>
          <span className={classes.fontSizeOptions}>
            {articulo.precios.venta || "0.00"}
          </span>
        </div>
      </TooltipWithoutIcon>
    </div>
  );
};

export default function AutocompleteArticulo({
  autocompleteValue,
  descripcion,
  handleChangeInput,
  handleOnChangeArticulo,
  loading,
  articulos,
}) {
  const classes = useStyles();
  const { configGeneral } = useSelector((state) => state.configGeneral);

  return (
    <Autocomplete
      fullWidth
      classes={{
        option: classes.option,
      }}
      autoHighlight={configGeneral.es_autoseleccionado}
      loading={loading}
      value={autocompleteValue}
      options={articulos}
      filterOptions={(options, state) => options}
      getOptionLabel={(option) => option.codProveedor}
      renderOption={(articulo) => <OptionsLabel articulo={articulo} />}
      inputValue={descripcion}
      onInputChange={(event, value, reason) => handleChangeInput(value, reason)}
      onInput={(e) => maxLength(e, 50)}
      onChange={(event, value, reason) => handleOnChangeArticulo(value)}
      renderInput={(params) => (
        <TextFieldAutocompleteArticulo
          {...params}
          size="medium"
          label="Busqueda por nombre o código"
          InputLabelProps={{
            classes: {
              root: classes.labelRoot,
            },
          }}
          variant="outlined"
        />
      )}
      id="buscador_articulos"
      name="buscador_articulos"
    />
  );
}
