import { Box, Divider, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import DetalleDescuentosYRecargos from "../DetalleDescuentosYRecargos";

export const DescriptionDetails = ({
  productoSelected,
  permisoParaEditarYVerTooltip,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography align="center" variant="h6">
          {productoSelected.descripcionProveedor}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Proveedor:</span>{" "}
        {productoSelected.proveedor ? productoSelected.proveedor : "---"}
      </Grid>

      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>
          Fecha de última modificación:
        </span>{" "}
        {productoSelected.fechaUltimaModificacion
          ? moment(productoSelected.fechaUltimaModificacion).format(
              "DD-MM-YYYY",
            )
          : "---"}
      </Grid>

      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Fecha de última venta:</span>{" "}
        {productoSelected.fecha_ultima_venta
          ? moment(productoSelected.fecha_ultima_venta).format("DD-MM-YYYY")
          : "---"}
      </Grid>

      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Fecha de última compra:</span>{" "}
        {productoSelected.fecha_ultima_compra
          ? moment(productoSelected.fecha_ultima_compra).format("DD-MM-YYYY")
          : "---"}
      </Grid>

      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Código original:</span>{" "}
        {productoSelected.codOriginal ? productoSelected.codOriginal : "---"}
      </Grid>

      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Código auxiliar:</span>{" "}
        {productoSelected.codAuxiliar ? productoSelected.codAuxiliar : "---"}
      </Grid>

      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Código auxiliar 2:</span>{" "}
        {productoSelected.codigo_auxiliar_2
          ? productoSelected.codigo_auxiliar_2
          : "---"}
      </Grid>

      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Código auxiliar 3:</span>{" "}
        {productoSelected.codigo_auxiliar_3
          ? productoSelected.codigo_auxiliar_3
          : "---"}
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Stock:</span>
        {productoSelected.repuesto_sucursal &&
        productoSelected.repuesto_sucursal.stockDisponible
          ? productoSelected.repuesto_sucursal.stockDisponible
          : "---"}
      </Grid>

      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Stock deseado:</span>{" "}
        {productoSelected.repuesto_sucursal &&
        productoSelected.repuesto_sucursal.stock_deseado
          ? productoSelected.repuesto_sucursal.stock_deseado
          : "---"}
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Precio lista:</span>{" "}
        {parseFloat(productoSelected.precios.lista).toLocaleString("es-AR") ||
          "---"}
      </Grid>

      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Precio costo:</span>{" "}
        {parseFloat(productoSelected.precios.costo).toLocaleString("es-AR") ||
          "---"}
      </Grid>

      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Precio venta:</span>{" "}
        {parseFloat(productoSelected.precios.venta).toLocaleString("es-AR") ||
          "---"}
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      {permisoParaEditarYVerTooltip && (
        <Grid item xs={12}>
          <span style={{ fontWeight: "bold" }}>Descuentos/Recargos:</span>{" "}
          <DetalleDescuentosYRecargos
            descuentosYRecargos={productoSelected.tipo_descuento}
          />
        </Grid>
      )}

      {permisoParaEditarYVerTooltip && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}

      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Rubro:</span>{" "}
        {productoSelected.rubro ? productoSelected.rubro.nombre : "---"}
      </Grid>

      <Grid item xs={12}>
        <span style={{ fontWeight: "bold" }}>Subrubro:</span>{" "}
        {productoSelected.sub_rubro ? productoSelected.sub_rubro.nombre : "---"}
      </Grid>
    </Grid>
  );
};
